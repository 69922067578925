<template>
  <div v-if="$store.state.home.user">
    <v-overlay
      :value="isAnyOpen"
      z-index="1010"
      class="main-overlay"
    ></v-overlay>

    <OnboardingImportInstanceSetupCompleteModal
      :isOpen="displayImportInstanceSetupCompleteModal"
      :importedInstance="importedInstance"
      :nameservers="nameservers"
      @next="endInstanceImportProcess"
    />

    <OnboardingFtpAccountConfigurationModal
      :isOpen="displayFtpAccountConfigurationModal"
      :importInstanceData="importInstanceData"
      :selectedService="selectedService"
      @next="handleFtpAccountConfigurationNext"
      @removeModal="deactivateAll"
    />
    <OnboardingImportChooseServiceModal
      :services="availableServices"
      :preSelectedService="selectedService"
      :isOpen="displayImportChooseServiceModal"
      @removeModal="deactivateAll"
      @goBack="deactivateAll"
      @service-selected="handleImportServiceSelected"
    />
    <OnboardingImportInstanceUrlSettingsModal
      :isOpen="displayImportInstanceUrlSettingsModal"
      @next="handleImportInstanceUrlSettingsNext"
      @removeModal="deactivateAll"
    />
    <OnboardingChooseCreationTypeModal
      :isOpen="displayChooseCreationTypeModal"
      @next="selectInstanceCreationType"
      @removeModal="deactivateAll"
    />

    <choose-onboarding-process
      :isOpen="displayChooseOnboardingTypeModal"
      @next="demoOnboardingSelected"
      @removeModal="deactivateAll"
    />

    <onboarding-installation-limit-modal
      :selectedService="selectedService"
      :vars="limitModalVars"
      :isOpen="displayLimitModal"
      @goBack="deactivateAll"
      @removeModal="deactivateAll"
    />

    <onboarding-installation-zero-data-modal
      :isOpen="displayZeroDataModal"
      @goBack="deactivateAll"
      @removeModal="deactivateAll"
    />

    <onboarding-choose-product-modal
      :item="currentlySelectedTheme"
      :loading="createSiteSubmitLoading"
      :services="availableServices"
      :preSelectedService="selectedService"
      :isOpen="
        displaySelectProductModal
          ? 'selectProduct'
          : displayWebsiteDomainModal
          ? 'setDomain'
          : displayWebsiteInstallationModal
          ? 'selectTitle'
          : false
      "
      @goBack="goBack"
      @removeModal="deactivateAll"
      @service-selected="serviceSelected"
      @change-theme-button-clicked="changeThemeButtonClicked"
      @change-service-button-clicked="changeServiceButtonClicked"
      @installWebsite="installWebsite"
      @verifyDomain="verifyDomain"
    />

    <!-- <website-installation-modal
      :item="currentlySelectedTheme"
      :isOpen="displayWebsiteInstallationModal"
    /> -->

    <installation-progress-modal
      :theme="currentlySelectedTheme"
      :isOpen="displayInstallationProgress"
      :completed="installationCompleted"
      :error="installationError"
      :installedInstance="installedInstance"
      @tryAgain="tryAgain"
      @gotoDashboard="gotoDashboard"
      @removeModal="deactivateAll"
    />

    <super-quick-installation-progress-modal
      :theme="currentlySelectedTheme"
      :isOpen="displaySuperQuickInstallationProgress"
      :completed="installationCompleted"
      :error="installationError"
      :installedInstance="installedInstance"
      @gotoDashboard="gotoDashboard"
      @tryAgain="tryAgain"
    />

    <onboarding-choose-template-modal
      :isOpen="displayChooseTemplate"
      :selectedService="selectedService"
      @goBack="gotoDashboard"
      @theme-selected="themeSelected"
      @removeModal="deactivateAll"
    />

    <new-instance-modal
      :isOpen="displayStandardForm"
      :selectedTheme="currentlySelectedTheme"
      :selectedService="selectedService"
      @removeModal="deactivateAll"
      @verifyDomain="verifyDomain"
    />

    <onboarding-verification-domain-modal
      :is-open="displayVerificationDomainModal"
      :selectedService="selectedService"
      :installationDetails="installationDetails"
      @removeModal="deactivateAll"
      @installWebsite="installWebsite"
    />
  </div>
</template>

<script>
import Api from "@/apis/Api";

// import WebsiteInstallationModal from "./WebsiteInstallationModal.vue";
import InstallationProgressModal from "./InstallationProgressModal.vue";
import SuperQuickInstallationProgressModal from "./SuperQuickInstallationProgressModal.vue";
import OnboardingChooseProductModal from "./OnboardingChooseProductModal.vue";
import OnboardingInstallationLimitModal from "./OnboardingInstallationLimitModal.vue";
import OnboardingInstallationZeroDataModal from "./OnboardingInstallationZeroDataModal.vue";
import OnboardingChooseTemplateModal from "./OnboardingChooseTemplateModal";
import NewInstanceModal from "./NewInstanceModal.vue";
import ChooseOnboardingProcess from "./ChooseOnboardingProcess.vue";
import OnboardingChooseCreationTypeModal from "./OnboardingChooseCreationTypeModal.vue";
import OnboardingImportInstanceUrlSettingsModal from "./OnboardingImportInstanceUrlSettingsModal.vue";
import OnboardingFtpAccountConfigurationModal from "./OnboardingFtpAccountConfigurationModal.vue";
import OnboardingImportInstanceSetupCompleteModal from "./OnboardingImportInstanceSetupCompleteModal.vue";
import OnboardingImportChooseServiceModal from "./OnboardingImportChooseServiceModal.vue";
import WpAdminMixin from "@/mixins/WpAdminMixin";
import OnboardingVerificationDomainModal from "@/components/onboarding/OnboardingVerificationDomainModal.vue";

export default {
  components: {
    OnboardingVerificationDomainModal,
    // WebsiteInstallationModal,
    InstallationProgressModal,
    SuperQuickInstallationProgressModal,
    OnboardingChooseProductModal,
    OnboardingInstallationLimitModal,
    OnboardingInstallationZeroDataModal,
    OnboardingChooseTemplateModal,
    NewInstanceModal,
    ChooseOnboardingProcess,
    OnboardingChooseCreationTypeModal,
    OnboardingImportInstanceUrlSettingsModal,
    OnboardingFtpAccountConfigurationModal,
    OnboardingImportInstanceSetupCompleteModal,
    OnboardingImportChooseServiceModal,
  },
  mixins: [WpAdminMixin],
  data() {
    return {
      installationCompleted: false,
      installationError: false,
      createSiteSubmitLoading: false,
      currentlySelectedTheme: {},
      loadingOnboarding: true,
      theme: "",
      installedInstance: {},
      ssoUrl: "",
      serverAccounts: [],
      selectedProduct: {},
      //
      limitModalVars: {
        limit: 0,
        upgrade_available: false,
      },
      availableServices: [],
      selectedService: {
        id: 0,
      },
      //
      goBack: () => {
        this.gotoDashboard();
      },
      importInstanceData: {
        url: "",
      },
      importedInstance: {},
      nameservers: [],
      installationDetails: {},
    };
  },
  methods: {
    endInstanceImportProcess() {
      this.$store.dispatch("deactivateAll");
      this.resetInstallation();
    },
    handleFtpAccountConfigurationNext(response) {
      this.importedInstance = response.data;
      this.nameservers = response.meta.nameservers;
      if (!this.isDemo) {
        this.$root.$emit("instance-created", response.data);
      }
      this.$store.dispatch("displayImportInstanceSetupCompleteModal");
    },
    handleImportInstanceUrlSettingsNext(url) {
      this.importInstanceData.url = url;
      this.$store.dispatch("displayFtpAccountConfigurationModal");
    },
    handleImportServiceSelected(selectedService) {
      this.selectedService = selectedService;
      this.$store.dispatch('displayImportInstanceUrlSettingsModal')
    },
    changeThemeButtonClicked() {
      this.currentlySelectedTheme = {};
      this.$store.dispatch("displayChooseTemplate");
    },
    changeServiceButtonClicked() {
      this.selectedService = {
        id: 0,
      };
      this.$store.dispatch("displaySelectProductModal");
    },
    gotoDashboard: function () {
      if (this.$route.name != "Instances") {
        this.$router.push({ name: "Instances" });
      }
      this.$store.dispatch("deactivateAll");
      this.resetInstallation();
    },
    chooseProductGoBack: function () {
      if (this.displaySelectProductBeforeTemplateSelect) {
        this.$store.dispatch("deactivateAll");
      } else {
        if (this.isStartingWithStandardForm) {
          this.$store.dispatch("displayStandardForm");
        } else {
          this.$store.dispatch("displayChooseTemplate");
        }
      }
    },
    deactivateAll: function () {
      if (this.displaySelectProductBeforeTemplateSelect) {
        this.selectedProduct = {};
      }
      this.deselectTheme();
      this.$store.dispatch("deactivateAll");
    },
    goBackChooseTemplate: function () {},
    themeSelected: function (item) {
      this.selectTheme(item);
      if (!this.selectedService.id) {
        let availableServices =
          this.$store.state.services.getServicesWithChooseTheme();
        this.availableServices = availableServices;
        if (availableServices.length === 1) {
          this.selectedService = availableServices[0];

          if (this.selectedService.install_method == "standard") {
            this.$store.dispatch("displayStandardForm");
            return;
          }

          if (this.selectedService.ask_for_domain) {
            this.$store.dispatch("displayWebsiteDomainModal");
          } else {
            this.$store.dispatch("displayWebsiteInstallationModal");
          }
          return;
        }
        this.$store.dispatch("displaySelectProductModal");
        return;
      }

      if (this.selectedService.install_method == "standard") {
        this.$store.dispatch("displayStandardForm");
        return;
      }

      if (this.selectedService.ask_for_domain) {
        this.$store.dispatch("displayWebsiteDomainModal");
      } else {
        this.$store.dispatch("displayWebsiteInstallationModal");
      }
    },
    // goBack: function () {},
    chooseTemplate: function () {},
    serviceSelected: function (service, quiet = false) {
      this.selectedService = service;
      if (quiet) {
        return;
      }

      if (this.selectedService.install_method == "super_quick") {
        if (this.selectedService.ask_for_domain) {
          this.$store.dispatch("displayWebsiteDomainModal");
          return;
        }

        if (this.selectedService.onboarding.super_quick_ask_for_site_name) {
          this.$store.dispatch("displayWebsiteInstallationModal");
          return;
        }

        this.$store.dispatch("deactivateAll");
        this.installWebsite({
          theme: this.currentlySelectedTheme,
          install_method: "super_quick",
        });
        return;
      }

      if (this.selectedService.install_method == "quick") {
        if (
          this.selectedService.is_installed_from_template
          && !this.selectedService.quick_allow_to_choose_themes
          && !this.selectedService.ask_for_domain
        ) {
          this.$store.dispatch("displayWebsiteInstallationModal");
          return;
        }

        if (
          this.selectedService.is_installed_from_template
          && !this.selectedService.quick_allow_to_choose_themes
          && this.selectedService.ask_for_domain
        ) {
          this.$store.dispatch("displayWebsiteDomainModal");
          return;
        }

        if (!this.currentlySelectedTheme.name) {
          this.$store.dispatch("displayChooseTemplate");
          return;
        }

        if (this.selectedService.ask_for_domain) {
          this.$store.dispatch("displayWebsiteDomainModal");
          return;
        }

        this.$store.dispatch("displayWebsiteInstallationModal");
        return;
      }

      if (
        this.selectedService.choose_theme &&
        !this.currentlySelectedTheme.name
      ) {
        this.$store.dispatch("displayChooseTemplate");
        return;
      }
      this.$store.dispatch("displayStandardForm");
    },
    tryAgain() {
      this.retryInstallWebsite();
    },
    resetInstallation: function () {
      this.currentlySelectedTheme = {};
      this.installationCompleted = false;
      this.installationError = false;
      this.installedInstance = {};
      this.selectedService = {
        id: 0,
      };
      this.importInstanceData = {
        url: "",
      };
    },
    selectInstanceCreationType: function (action) {
      if (action === 0) {
        this.installationStart();
      } else if (action === 1) {
        if (
          this.$store.state.services.hasLimitReached()
          && this.$store.state.services.hasOneActiveService()
        ) {
          this.selectedService = this.$store.state.services.getActiveServices()[0];
          this.$store.dispatch("displayLimitModal");
          return;
        }

        this.availableServices = this.$store.state.services.getActiveServices();
        if (this.availableServices.length > 0) {
          this.selectedService = this.availableServices[0];
        }
        this.$store.dispatch("displayImportChooseServiceModal");
      }
    },
    installWebsite: function ({ theme, name, domain, install_method }) {
      if (this.isDemo) {
        this.$store.dispatch("displayInstallationProgress");
        setTimeout(() => {
          this.installationCompleted = true;
          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: `Instance "${name}" installed successfully`,
          });
        }, 3000);
        return;
      }

      this.createSiteSubmitLoading = true;

      Api.post(`/instances/install`, {
        theme: theme.slug,
        name: name,
        domain,
        service_id: this.selectedService.id,
        install_method,
      })
        .then((response) => {
          this.$root.$emit("instance-created", response.data.data);
          if (this.selectedService.install_method == "super_quick") {
            this.installedInstance = response.data.data;
            this.$store.dispatch("displaySuperQuickInstallationProgress");
          } else {
            this.$store.dispatch("displayInstallationProgress");
          }
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.createSiteSubmitLoading = false;
        });
    },
    retryInstallWebsite(instanceId, params) {
      if (!instanceId) {
        instanceId = this.installedInstance.id;
      }
      if (!params) {
        params = this.installedInstance.install_details;
      }
      this.installationCompleted = false;
      this.installationError = false;
      Api.put(`/instances/${instanceId}/retry-install`, params)
        .then((response) => {
          this.$root.$emit("instance-item-changed", response.data.data);
          if (this.displayInstallationProgress) {
            this.$store.dispatch("displayInstallationProgress");
          } else if (this.displaySuperQuickInstallationProgress) {
            this.$store.dispatch("displaySuperQuickInstallationProgress");
          } else {
            this.gotoDashboard();
          }
        })
        .catch((error) => {
          this.installationCompleted = true;
          this.installationError = true;
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        });
    },
    retryImportWebsite(instanceId) {
      this.installationCompleted = false;
      this.installationError = false;
      Api.put(`/instances/${instanceId}/import/quick-retry`)
        .then((response) => {
          this.$root.$emit("instance-item-changed", response.data.data);
        })
        .catch((error) => {
          this.installationCompleted = true;
          this.installationError = true;
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        });
    },
    deselectTheme: function () {
      this.currentlySelectedTheme = {};
    },
    selectTheme: function (theme) {
      this.currentlySelectedTheme = theme;
    },
    demoOnboardingSelected(classic) {
      this.$root.$emit("app-ready-to-render", true);
      localStorage.removeItem(
        window.location.origin + "-autostartInstallation"
      );
      if (classic) {
        this.availableServices = this.$store.state.services.getActiveServices();
        if (this.availableServices.length > 0) {
          this.selectedService = this.availableServices[0];
        }
        this.$store.dispatch("displayStandardForm");
        return;
      }
      this.$store.dispatch("displayChooseTemplate");
    },
    installationStart: function () {
      // handle browser back button

      history.pushState({}, "", "");

      window.onpopstate = () => {
        if (this.isAnyOpen) {
          this.$store.dispatch("deactivateAll");
          this.resetInstallation();
        }
      };

      this.resetInstallation();
      this.goBack = () => {
        this.gotoDashboard();
      };

      if (this.isDemo) {
        this.$store.dispatch("displayChooseOnboardingTypeModal");
        return;
      }

      if (!this.$store.state.services.hasActiveService()) {
        this.$store.dispatch("displayZeroDataModal");
        return;
      }

      if (
        this.$store.state.services.hasLimitReached()
        && this.$store.state.services.hasOneActiveService()
      ) {
        this.selectedService = this.$store.state.services.getActiveServices()[0];
        this.$store.dispatch("displayLimitModal");
        return;
      }

      if (this.$store.state.services.allServicesHaveChooseTheme()) {
        this.goBack = () => {
          this.$store.dispatch("displayChooseTemplate");
        };
        this.$store.dispatch("displayChooseTemplate");
        return;
      }

      if (this.$store.state.services.hasServiceWithoutChooseTheme()) {
        this.availableServices = this.$store.state.services.getActiveServices();
        this.$store.dispatch("displaySelectProductModal");
        return;
      }

      this.$store.dispatch("displayStandardForm");
    },
    verifyDomain: function (installationDetails) {
      this.installationDetails = installationDetails;
      this.$store.dispatch("displayVerificationDomainModal");
    }
  },
  created: function () {
    if (this.isAutoStartEnabled) {
      this.installationStart();
    }

    this.$root.$on("instance-installation-finished", (data) => {
      if (this.selectedService.install_method == "super_quick") {
        this.wpAdminSsoRedirect(data, this.selectedService);
        return;
      }

      this.installationCompleted = true;
      this.installationError = false;
      this.installedInstance = data;
    });
    this.$root.$on("instance-installation-failed", (data) => {
      this.installationCompleted = true;
      this.installationError = true;
      this.installedInstance = data;
    });
  },
  mounted: function () {
    this.$root.$on("instance-retry-install", (instance) => {
      if (instance.imported) {
        this.retryImportWebsite(instance.id);
        return;
      }
      let params = instance.install_details;
      if (params.install_method == "quick") {
        this.retryInstallWebsite(instance.id, params);
      } else {
        this.$root.$emit("instance-retry-install-with-form", instance);
        this.$store.dispatch("displayStandardForm");
      }
    });

    this.$store.subscribeAction((action) => {
      if (action.type === "startInstallationProcess") {
        // this.installationStart();
        this.$store.dispatch("displayChooseCreationTypeModal");
      }
    });
  },
  watch: {
    isAutoStartEnabled(enabled) {
      if (enabled) {
        this.$root.$emit("app-ready-to-render", false);
        this.installationStart();
        return;
      }
      this.$root.$emit("app-ready-to-render", true);
    },
    isAnyOpen: function (value) {
      if (value) {
        setTimeout(() => {
          document.querySelector("html").classList.add("overflow-y-hidden");
        }, 300);
      } else {
        setTimeout(() => {
          document.querySelector("html").classList.remove("overflow-y-hidden");
        }, 150);
      }
    },
  },
  computed: {
    isAnyOpen: function () {
      if (
        this.displayImportInstanceSetupCompleteModal ||
        this.displayFtpAccountConfigurationModal ||
        this.displayImportInstanceUrlSettingsModal ||
        this.displayChooseCreationTypeModal ||
        this.displayChooseOnboardingTypeModal ||
        this.displaySelectProductModal ||
        this.displayInstallationProgress ||
        this.displaySuperQuickInstallationProgress ||
        this.displayLimitModal ||
        this.displayZeroDataModal ||
        this.displayChooseTemplate ||
        this.displayStandardForm ||
        this.displayWebsiteDomainModal ||
        this.displayWebsiteInstallationModal ||
        this.displayImportChooseServiceModal ||
        this.displayVerificationDomainModal
      ) {
        return true;
      } else {
        return false;
      }
    },
    isDemo() {
      return (
        typeof this.$store.state.home.user !== "undefined" &&
        this.$store.state.home.user.email == "demo@panelalpha.com"
      );
    },
    isAutoStartEnabled() {
      return (
        this.isDemo &&
        localStorage.getItem(window.location.origin + "-autostartInstallation")
      );
    },
    // isLimitReached: function () {
    //   return false;
    // },
    // isStartingWithStandardForm: function () {
    //   return false;
    // },
    // chooseThemeToStandardForm: function () {
    //   return false;
    // },
    // displaySelectProductBeforeTemplateSelect: function () {
    //   return true;
    // },
    displayImportInstanceSetupCompleteModal: function () {
      return this.$store.state.InstallationModule
        .displayImportInstanceSetupCompleteModal;
    },
    displayFtpAccountConfigurationModal: function () {
      return this.$store.state.InstallationModule
        .displayFtpAccountConfigurationModal;
    },
    displayImportInstanceUrlSettingsModal: function () {
      return this.$store.state.InstallationModule
        .displayImportInstanceUrlSettingsModal;
    },
    displayChooseCreationTypeModal: function () {
      return this.$store.state.InstallationModule
        .displayChooseCreationTypeModal;
    },

    displayChooseOnboardingTypeModal: function () {
      return this.$store.state.InstallationModule
        .displayChooseOnboardingTypeModal;
    },
    displaySelectProductModal: function () {
      return this.$store.state.InstallationModule.displaySelectProductModal;
    },
    displayInstallationProgress: function () {
      return this.$store.state.InstallationModule.displayInstallationProgress;
    },
    displaySuperQuickInstallationProgress: function () {
      return this.$store.state.InstallationModule
        .displaySuperQuickInstallationProgress;
    },
    displayLimitModal: function () {
      return this.$store.state.InstallationModule.displayLimitModal;
    },
    displayZeroDataModal: function () {
      return this.$store.state.InstallationModule.displayZeroDataModal;
    },
    displayChooseTemplate: function () {
      return this.$store.state.InstallationModule.displayChooseTemplate;
    },
    displayStandardForm: function () {
      return this.$store.state.InstallationModule.displayStandardForm;
    },
    displayWebsiteInstallationModal: function () {
      return this.$store.state.InstallationModule
        .displayWebsiteInstallationModal;
    },
    displayWebsiteDomainModal: function () {
      return this.$store.state.InstallationModule.displayWebsiteDomainModal;
    },
    displayImportChooseServiceModal() {
      return this.$store.state.InstallationModule
        .displayImportChooseServiceModal;
    },
    displayVerificationDomainModal() {
      return this.$store.state.InstallationModule
        .displayVerificationDomainModal;
    }
    // isAnyModalOpen: function() {
    //   if(this.displayWebsiteInstallationModal || this.)
    // }
  },
};
</script>

<style></style>
